@import 'flag-icons/css/flag-icons.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.header-safe-area {
  padding-top: env(safe-area-inset-top);
  height: calc(60px + env(safe-area-inset-top));
}

.safe-area {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
.safe-area-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.content {
  background-color: blue; /* Set your desired color for the content area */
  padding: 20px;
  border-radius: 10px;
}

@layer base {
  :root {
    --foreground-rgb: 255, 255, 255;
    --text-color-rgb: 43, 50, 70;
  }

  html {
    -webkit-overflow-scrolling : touch !important;
  }

  body {
    color: rgb(var(--foreground-rgb));
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-overflow-scrolling : touch !important;
    font-synthesis: none !important;
  }

  p {
    color: rgb(var(--text-color-rgb));
  }
}

@layer components {
  .header-title {
    @apply body2 inline-block rounded-lg px-2 py-1 text-grey-800 hover:text-blue-900;
  }
  .btn-blue-states {
    @apply rounded-xl bg-[#326BFF] font-semibold text-white hover:bg-blue-550 focus:bg-[#2154D8] focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 disabled:bg-blue-200;
  }

  .input-field {
    @apply min-w-[250px] text-black placeholder:text-grey-800 focus:border-[#326BFF] focus:ring-0;
  }

  .loader-container {
    @apply relative inline-flex items-center justify-center;
  }

  .loader-circle {
    @apply border-b-transparent border-l-transparent;
  }

  .h1 {
    @apply text-[48px] font-bold leading-[56px] text-blue-900;
  }

  .h2 {
    @apply text-[40px] font-bold leading-[48px] text-blue-900;
  }

  .h3 {
    @apply text-[32px] font-semibold leading-[40px] text-blue-900;
  }

  .h4 {
    @apply text-[24px] font-semibold leading-[28px] text-blue-900;
  }

  .h5 {
    @apply text-[20px] font-semibold leading-[24px] text-blue-900;
  }

  .h6 {
    @apply text-[15px] font-bold leading-[20px] text-blue-900;
  }

  .h1-inter {
    @apply text-[48px] font-semibold leading-[56px] text-blue-900;
  }
  .h2-inter {
    @apply text-[36px] font-semibold leading-[40px] text-blue-900;
  }

  .h3-inter {
    @apply text-[32px] font-semibold leading-[40px] text-blue-900;
  }

  h1 {
    @apply h1;
  }

  h2 {
    @apply h2;
  }

  h3 {
    @apply h3;
  }

  h4 {
    @apply h4;
  }

  h5 {
    @apply h5;
  }

  h6 {
    @apply h6;
  }
  .body1 {
    @apply text-[17px] font-medium leading-[24px] text-grey-800; /* 17px*/
  }
  .body1-bold {
    @apply body1 text-[18px] font-semibold; /* 18px*/
  }
  .body1-blog {
    @apply body1 text-[17px] font-[450]; /* 18px*/
  }
  .body2 {
    @apply text-[15px] font-medium leading-[20px] text-grey-800; /* 15px*/
  }
  .body2-bold {
    @apply body2 font-bold;
  }
  .body2-blog {
    @apply body2 font-[425] tracking-[0.2px] leading-[22px];
  }
  .body3 {
    @apply text-[13px] font-medium leading-[18px] text-grey-800; /* 13px*/
  }
  .body3-bold {
    @apply body3 font-bold;
  }
  .caption {
    @apply text-[11px] font-medium leading-[16px] tracking-[0.2px] text-grey-800; /* 11px*/
  }
  .caption-bold {
    @apply caption font-bold;
  }

  .search-input {
    @apply hover:bg-gray-100 flex flex-col content-center items-start justify-center gap-y-[3px] rounded-full border-[1px] px-[1.75rem] pb-3 pt-[7px] md:flex md:border-r md:border-transparent md:px-7;
  }
}

@layer utilities {
  * {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", Arial, Helvetica, sans-serif;
    @apply tracking-[0.2px];
  }
  body {
    @apply min-h-[100dvh];
  }
  /* LOADING SPINNER */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-container {
    @apply mx-4 flex min-h-[calc(100vh-130px)] items-center justify-center rounded-xl bg-blue-50;
  }

  .loading-spinner {
    width: 5px;
    height: 5px;
    border: 6px solid transparent; /* white */
    border-top: 6px solid #326bff; /* blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .loading-spinner-small {
    width: 3px;
    height: 3px;
    border: 4px solid #ffffff; /* white */
    border-top: 4px solid #326bff; /* blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .loading-spin {
    width: 20px;
    height: 20px;
    border: 3px solid #326bff;
    border-right-color: transparent;
    border-radius: 100%;
    animation: spinner 1.5s linear infinite;
  }

  .loading-spin-white {
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-right-color: transparent;
    border-radius: 100%;
    animation: spinner 1.5s linear infinite;
  }



 /* Custom scrollbar */
 .custom-scroll::-webkit-scrollbar {
     width: 10px;
     height: 32px;
   }
  .custom-scroll::-webkit-scrollbar-track {
    background: transparent;
   }
  .custom-scroll::-webkit-scrollbar-thumb {
    background: rgb(229, 231, 235);
    border-radius: 6px;
    flex-shrink: 0;
  }

  /* CUSTOM Shadow */
  .shadow-sharp {
    box-shadow: 0 8px 32px 0 rgba(0, 0, 102, 0.25);
  }

  .shadow-far {
    box-shadow: 0 8px 48px 0 rgba(0, 0, 102, 0.1);
  }

  .shadow-close {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 102, 0.05);
  }

  .shadow-image {
    border-radius: 100%;
    box-shadow: 30px 30px 50px 80px rgba(255, 255, 255, 1);
  }

  /* Markdown styles */
  .markdown-article {
    @apply prose-p:prose-p:text-blue-900 prose-p:font-medium prose-p:text-[17px] leading-[26px] prose-p:tracking-[0.1px] prose-strong:font-semibold prose-strong:text-blue-900 prose-strong:tracking-[0.6px]
    prose-table:w-full prose-tr:body3 prose-tr:border-b prose-tr:text-grey-600 prose-th:text-left prose-td:py-3 prose-td:pl-3 md:prose-td:pr-0 prose-td:px-0 prose-th:py-3 prose-th:pl-3;
  }

  .markdown-article .ck-table-resized tr td:nth-last-child(-n + 3),
  .markdown-article .ck-table-resized tr td:nth-last-child(-n + 3) span {
    white-space: nowrap !important;
  }

  /* Custom table styles */
  .markdown-article .table {
    display: block;
    overflow-x: auto;
  }

  .remove-arrow::-webkit-inner-spin-button,
  .remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .remove-arrow {
    -moz-appearance: textfield;
  }

  .markdown-article h2 {
    @apply h5 font-semibold;
  }

  .markdown-article h3 {
    @apply text-lg font-semibold;
  }

  .markdown-article li {
    @apply text-blue-900 ml-4 list-disc;
  }

  .markdown-article li li {
    @apply text-blue-900 ml-5 list-decimal;
  }

  .markdown-bank {
    @apply text-[15px] tracking-[0.2px] text-grey-800;
  }

  .markdown-bank a {
    @apply text-blue-500;
  }

  .markdown-bank p {
    @apply text-[15px] tracking-[0.2px] text-grey-800;
  }

  .markdown-bank .ck-table-resized tr td:nth-last-child(-n + 3),
  .markdown-bank .ck-table-resized tr td:nth-last-child(-n + 3) span {
    white-space: nowrap !important;
  }

  /* Custom table styles */
  .markdown-bank .table {
    display: block;
    overflow-x: auto;
  }

  .markdown-bank h2 {
    @apply h5 font-semibold;
  }

  .markdown-bank h3 {
    @apply text-lg font-semibold;
  }

  .markdown-bank li {
    @apply text-[15px] tracking-[0.2px] text-grey-800 ml-4 list-disc;
  }

  .markdown-bank li li {
    @apply text-[15px] tracking-[0.2px] text-grey-800 ml-5 list-decimal;
  }

  @media (max-width: 727px) {
    .markdown-article .table table {
      display: flex;
      flex-direction: column;
    }
    .markdown-article .table tbody {
      display: flex;
      flex-direction: column;
    }
    .markdown-article .table th {
      display: none;
    }
    .markdown-article .table tr {
      display: grid;
      grid-template-columns: 2fr 1fr 0.3fr;
      padding: 16px 0;
    }
    .markdown-article .table td {
      display: flex;
      padding: 0 8px;
    }
    .markdown-article .table td:nth-child(1) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
      justify-content: flex-end;
    }
    .markdown-article .table td:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .markdown-article .table td:nth-child(2) span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
    }
    .markdown-article .table td:nth-child(3) {
      display: none;
    }
    .markdown-article .table td:nth-child(4) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      justify-content: flex-end;
    }
    .markdown-article .table td:nth-child(5) {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      align-items: center;
    }
  }

  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.modal-overlay {
  @apply fixed top-0 left-0 w-full h-full flex justify-center items-center sm:py-8;
  background-color: rgba(151, 162, 190, 0.5);
}

.modal {
  @apply w-full sm:w-max bg-white h-screen sm:h-max sm:rounded-3xl overflow-scroll py-12 relative;
  max-height: 100%;
  box-shadow: 0 8px 48px 0 #0000661A;
}

.select-input {
  -webkit-appearance: none;
}
.seo-content ul {
  @apply list-disc list-inside divide-y divide-blue-100 border-y border-blue-100;
}

.seo-content li {
  @apply body2 text-grey-800 pt-3 pb-3 marker:content-[''] before:h-1 before:w-1 before:content-[''] before:block flex before:mr-[14px] before:ml-[6px] before:shrink-0 before:bg-blue-500 items-center before:rounded-full tracking-[0.2px];
}

.seo-content li a {
  @apply text-blue-500;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8EAEFF;
  color: #8EAEFF;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8EAEFF;
  color: #8EAEFF;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8EAEFF;
  color: #8EAEFF;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

.savings-accounts-grid {
  @apply grid grid-cols-4 gap-3;
  grid-template-columns: repeat(3, 1fr) 24px;
}

.banks-item-grid {
  @apply grid grid-cols-2 gap-3 w-full max-w-full;
  grid-template-columns: 1fr 1fr;
}

.PhoneInputInput {
  outline: none;
}

@keyframes dot-flashing {
  0% {
    background-color: #8EAEFF;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
